import './form-input-alt.styles.scss';

const FormInputAlt = ({ label, ...otherProps }) => {
    return (
        <div className="group">
            <input className="form-input-alt" {...otherProps}></input>
            {label && (
            <label className={
                `${otherProps.value.length ? 'shrink' : ''} form-input-label-alt`}>
                {label} 
            </label>
            )}
            

            
        </div>
    )
}

export default FormInputAlt;