import { useState } from "react";
import { createAuthUserWithEmailAndPassword, createUserDocumentFromAuth } from "../../utils/firebase/firebase.utils";
import Button, { BUTTON_TYPE_CLASSES } from "../button/button.component";
import { ContainerFormSignUp, ContainerLogoSgUp, ContainerSignUp } from "./sign-up-form.styles";
import FormInputAlt from "../form-input-alt/form-input-alt.component";
import { message } from "antd";
import { Navigate, useNavigate } from "react-router-dom";
import { ReactComponent as LogoHorizontal } from '../../assets/logo-horizontal.svg';


const defaultFormFields = {
    displayName: '',
    email: '',
    password: '',
    confirmPassword: ''
}

const SignUpForm = () => {
    const [formFields, setFormFields] = useState(defaultFormFields);
    const { displayName, email, password, confirmPassword } = formFields;
    const [messageApi, contextHolder] = message.useMessage();
    const navigate = useNavigate();

    const resetFormFields = () => {
        setFormFields(defaultFormFields)
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (password !== confirmPassword) {
            alert('Senhas não conferem');
            return;
        }

        try {
            const { user } = await createAuthUserWithEmailAndPassword(email, password);
            await createUserDocumentFromAuth(user, { displayName });
            messageApi.open({
                type: 'success',
                content: 'Usuário Criado com Sucesso',
                duration: '2',
                style: {
                    margin: '5rem 0 0 0 ',
                }
            });
            resetFormFields();
            setTimeout(() => {
                navigate('/');
                window.location.reload();
            }, 1000)
        } catch (error) {
            if (error.code === 'auth/email-already-in-use') {
                messageApi.open({
                    type: 'error',
                    content: 'Email já cadastrado',
                    duration: '2',
                    style: {
                        margin: '5rem 0 0 0 ',
                    }
                });
            } else {
                messageApi.open({
                    type: 'error',
                    content: 'Erro ao criar Usuário',
                    duration: '2',
                    style: {
                        margin: '5rem 0 0 0 ',
                    }
                });
                console.log('Nao foi possível criar usuário', error)
            }
        }

    }

    const handleChange = (event) => {
        const { name, value } = event.target;

        setFormFields({ ...formFields, [name]: value })
    };

    return (
        <ContainerSignUp>
            <ContainerLogoSgUp>
                <Button texto={String.fromCharCode(5176)} buttonStyle={BUTTON_TYPE_CLASSES.svg} onClick={() => navigate('/')} hover={'opacity: 1'}
                    bgColor={'#f0f0f0'} color={'#396600'} fontSize={'1.4rem'} width={'35px'} borderRadius={'50%'} height={'35px'} margin={'auto 0'}
                />
                <LogoHorizontal />
            </ContainerLogoSgUp>

            {contextHolder}

            <ContainerFormSignUp>
                <h3>Criar conta</h3>
                <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                    <FormInputAlt type='text' required label='Nome Completo' name='displayName' onChange={handleChange} value={displayName} ></FormInputAlt>
                    <FormInputAlt type='email' required name='email' onChange={handleChange} value={email} label='Email'></FormInputAlt>
                    <FormInputAlt type='password' required name='password' onChange={handleChange} value={password} label='Senha' ></FormInputAlt>
                    <FormInputAlt type='password' required name='confirmPassword' onChange={handleChange} value={confirmPassword} label='Confirme a senha' ></FormInputAlt>

                    <Button texto={'Cadastrar'} buttonStyle={BUTTON_TYPE_CLASSES.svg} onClick={handleSubmit} margin={'2rem 0 0 auto'}
                        bgColor={'#396600'} hover={'opacity: 1'}
                    />
                </form>
            </ContainerFormSignUp>
        </ContainerSignUp>
    )
}

export default SignUpForm;