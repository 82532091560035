import { Container, Barra, Numero } from "./progress-bar.styles";


const ProgressBar = (props) => {

    const { bgcolor, completed} = props;
    
    return (
        <Container>
            <Barra  bgcolor={bgcolor}  completed={completed} >
                <Numero>{`${completed}%`}</Numero>
            </Barra>
        </Container>
    );

}

export default ProgressBar;

