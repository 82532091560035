import styled from "styled-components";

const cinzaleve = '#ced4da';

export const InputDados = styled.input`
    border: 1px solid ${cinzaleve};
    border-radius: 0.25rem;
    font-size: 1rem;
    line-height: 1rem;
    padding: 0.3em 0.55em;
    width: 95%;

    &:focus {
        outline: none;
        border-color:#b1ce0b
    }

    -webkit-appearance:     none;
    -moz-appearance:        none;
    -ms-appearance:         none;
    -o-appearance:          none;
    appearance:             none;
`

export const ContainerInput = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    flex-direction: column;
    margin: 0.3rem 0.5rem 0.5rem 0.5rem;

`

export const LabelEdit = styled.label`
    font-size: 0.8rem;
    
`