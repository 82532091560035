import styled from "styled-components";
import { Link } from "react-router-dom";

export const NavigationContainer = styled.div`
    
    border-radius: 10px;
    box-shadow:0 0 2px rgb(38 41 33 / 25%);
    display: flex;
    justify-content: space-between;
    
`

export const LogoContainer = styled.div`
    box-sizing: border-box;
    width: 250px;
    padding: 15px;
    position: relative;
    vertical-align: middle;
`

export const NavLinks = styled.div`
    margin: auto 0;
    display: flex;
    justify-content: space-evenly;
    width: 25%;
    //min-height: 120px;
    align-items: center;
`

export const NavLink = styled(Link)`
    /*svg {
        fill: green;
    }*/
    
`