import styled from "styled-components";

export const Container = styled.div`
    height: 15px;
    width: 95%;
    background-color: rgb(240, 240, 240);
    border-radius: 50px;
    margin: 1rem auto;

`

export const Barra = styled.div`
    height: 100%;
    width: ${(props) => (`${props.completed}%`)};
    background-color : ${(props) => props.bgcolor};
    border-radius: inherit;
    text-align: right;
    
    vertical-align: text-bottom;

`

export const Numero = styled.span`
    position: relative;
    padding: 0 8px;
    color: white;
    font-weight: 500;
    top: -5px;
    font-size: 0.9rem;
    
`

