import styled from "styled-components";

export const STr = styled.tr`
    font-size: 0.9rem;
    &:hover {
        background-color: #eeeeeeb0;
        
    }
`

export const ClienteTipo = styled.div`
    background-color: ${props => props.bgColor};
    padding: 0.3rem;
    width: 70px;
    font-size: 0.8rem;
    border-radius: 7px;
    text-align: center;
    opacity: 0.7;
    margin: 0 auto;
`
