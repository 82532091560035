import { initializeApp } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider, createUserWithEmailAndPassword, browserSessionPersistence, setPersistence, onAuthStateChanged, signOut } from 'firebase/auth';

import { getFirestore, doc, getDoc, setDoc, collection, writeBatch, query, getDocs, where, updateDoc, deleteDoc, orderBy } from 'firebase/firestore'

const firebaseConfig = {
    apiKey: "AIzaSyAZSCzaXHMnceYL2YRK-lzUfEv2iCe101o",
    authDomain: "aplicacao-mei.firebaseapp.com",
    projectId: "aplicacao-mei",
    storageBucket: "aplicacao-mei.appspot.com",
    messagingSenderId: "365418296523",
    appId: "1:365418296523:web:1bb60a8d00c0c41bfad74e"
};
  

const firebaseApp = initializeApp(firebaseConfig);

const googleProvider = new GoogleAuthProvider();

googleProvider.setCustomParameters({
    prompt: "select_account"
});

export const auth = getAuth();
export const signInWithGooglePopUp = () => signInWithPopup(auth, googleProvider);


export const db = getFirestore();

export const createUserDocumentFromAuth = async(userAuth, additionalInformation) => {
    if (!userAuth) return;

    const userDocRef = doc(db, 'users', userAuth.uid);

    const userSnapshot = await getDoc(userDocRef);

    if(!userSnapshot.exists()) {
        const { displayName, email } = userAuth;
        const createdAt = new Date();
    
        try {
            await setDoc(userDocRef, {
                displayName,
                email,
                createdAt,
                ...additionalInformation
            });
        } catch (error) {
            console.log('error creating the user', error.message)
        }
    
    }

    return userDocRef;    

};

export const addCollection = async(collectionKey, object) => {
    const collectionRef = collection(db, collectionKey)

    const batch = writeBatch(db);
    const docRef =doc(collectionRef, object.cpf);
    batch.set(docRef, object)

    await batch.commit();
}

export const createClientFromForm = async (clienteInfo) => {
    
    const createdAt = new Date();
    const dateTimestampForId = createdAt.getTime().toString();
    
    const clientDocRef = doc(db, 'clientes', dateTimestampForId);
    const cpfDocRef = doc(db, 'CPFs', clienteInfo.cpf)

    const clientSnapshot = await getDoc(clientDocRef);
    const cpfSnapShot = await getDoc(cpfDocRef);

   if((!cpfSnapShot.exists())&&(!clientSnapshot.exists())) {
        try {
            await setDoc(clientDocRef,{
                    createdAt,
                    dateTimestampForId,
                    ...clienteInfo
            })
            await setDoc(cpfDocRef, {
                createdAt
            })
            return true;
        } catch (error) {
            console.log('erro ao criar cliente', error.message)
        }
    }

    if(cpfSnapShot.exists()) {
        return false;
    }

    
}

export const getClientsDocuments = async() => {
    const collectionRef = collection(db, 'clientes');
    const q = query(collectionRef, orderBy('createdAt', 'desc'));

    const querySnapShot = await getDocs(q);
    const documentMap = querySnapShot.docs.reduce((acc, docSnapshot, idx) => {
        const {id} = docSnapshot;
        const dados = docSnapshot.data();
        const date = dados.createdAt.toDate();
        dados.id = id;
        dados.dateString = String(date.getDate()).padStart(2, '0') + '/' +  String(date.getMonth()+1).padStart(2, '0') + '/' + date.getFullYear() + '\n' 
        + String(date.getHours()).padStart(2, '0') + ':' + String(date.getMinutes()).padStart(2, '0') + ':' + String(date.getSeconds()).padStart(2, '0');

        acc[idx] = dados;
        return acc;
    }, [])

    return documentMap;
}

getClientsDocuments();

export const getClienteData = async(id) => {
    const collectionRef = collection(db, 'clientes');
    const q = query(collectionRef, where('dateTimestampForId', "==", id));

    const querySnapShot = await getDocs(q);
    const clienteInfo = querySnapShot.docs.reduce((acc, docSnapshot) => {
        acc = docSnapshot.data();

        return acc;
    }, [])

    return clienteInfo;
}

export const updateCliente = async(id, {...dadosEditados}) => {
    const clienteDoc = doc(db, 'clientes', id);
    const newFields = dadosEditados;

    console.log(newFields)
    try {
        await updateDoc(clienteDoc, newFields);
    } catch (error) {
        console.log(error + 'não foi possivel atualizar dados');
    }
    
}

export const deleteCliente = async (id, cpf) => {
    try {
        await deleteDoc(doc(db, 'clientes', id))
        await deleteDoc(doc(db, 'CPFs', cpf))
    } catch (error) {
        console.log(error + ' não foi possível deletar cliente');
    }
}


export const createAuthUserWithEmailAndPassword = async (email, password) => {
    if (!email || !password) return;

    return await createUserWithEmailAndPassword(auth, email, password);
}

export const signInAuthUserWithEmailAndPassword = async (email, password) => {
    if (!email || !password) return;

    try {
        setPersistence(auth, browserSessionPersistence)
    
        return await signInWithEmailAndPassword(auth, email, password);
    } catch (error) {
        switch(error.code) {
            case 'auth/wrong-password':
                alert('Senha incorreta')
                break
            case 'auth/user-not-found':
                alert('Email não está associado a um usuário');
                break;
            default:
                console.log(error);
        }
        console.log(error + ' não foi possivel logar')
    }
}

export const signOutUser = async () => {signOut(auth)};

export const onAuthStateChangedListener = (callback) => onAuthStateChanged(auth, callback);