import styled from "styled-components";


export const ContainerDados = styled.div`
    //border-bottom: 1px solid #286e0042;
    margin: 0.3rem 0.5rem 0.5rem 0.5rem;
    box-shadow: -1px 2px 3px -2px #a5c017;
    max-height: 150px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding-left: 0.3rem;
    text-overflow: ellipsis;
    
`

export const TituloDados = styled.span`
    font-size: 0.8rem;
`

export const ConteudoDados = styled.span`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    
    
`