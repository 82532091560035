import styled from "styled-components"

export const NavSty = styled.nav`
    ul {
        list-style-type: none;
        display: flex;
        justify-content: flex-end;
        
        li {
            margin: 0 0.5rem 0 0.5rem;
        }
    }
    
    
`
