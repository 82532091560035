import SearchBox from "../search-box/search-box.component";
import Button, {BUTTON_TYPE_CLASSES} from "../button/button.component";
import { SearchContainer } from "./search-form.styles";

import { ClientesContext } from "../../contexts/clientes.context";
import { useContext, useState } from "react";
import { ReactComponent as IconeLupa} from '../../assets/Icone-Lupa.svg'
import { ReactComponent as IconX} from '../../assets/timesX-svgrepo-com.svg'
import { SearchButtonContainer } from "../search-box/search-box.styles";

const defaultSearchFields = {
    cpf: '',
    nome: '',
    telefone:'',
    cnpj: '',
    dataIni:'',
    dataFim:''
}


const SearchForm = () => {
    const { clientesMap, getClientsMap, retornaPesquisa } = useContext(ClientesContext);
    const [searchFields, setSearchFields] = useState(defaultSearchFields)
    const  {cpf, nome, telefone, cnpj, dataIni, dataFim}  = searchFields;

    const handleChange = (event) => {
        const {name, value} = event.target;

        setSearchFields({...searchFields, [name]:value})
        
    }

    const submitHandler = (event) => {
        event.preventDefault();
        const entradas = {}
        
        if(Object.values(searchFields).every(field => field == '')) {
            getClientsMap()
        } else {
            for(const [key, value] of Object.entries(searchFields)) {
                if(value.length > 0) {
                    entradas[key] = value;
                }
            }
            retornaPesquisa(entradas, clientesMap)
        }
        
    }

    const limpaCampos = () => {
        setSearchFields(defaultSearchFields);
        getClientsMap();
    }

    return (
    <>
    <SearchContainer onSubmit={submitHandler}>
        <SearchBox onChange={handleChange} value={cpf} name='cpf' label={'CPF'} type='text' />
        <SearchBox onChange={handleChange} value={nome} name='nome' label={'Nome'} type='text' />
        <SearchBox onChange={handleChange} value={telefone} name='telefone' label={'Telefone'} type='text' />
        <SearchBox onChange={handleChange} value={cnpj} name='cnpj' label={'CNPJ'} type='text' />
        <SearchBox onChange={handleChange} value={dataIni} name='dataIni' label={'Data Inicial'} type='date' />
        <SearchBox onChange={handleChange} value={dataFim} name='dataFim' label={'Data Final'} type='date' />

        <SearchButtonContainer >
        <Button 
        texto={'Pequisar'} hover={'opacity: 1'} margin={'0.5rem 0 0 0'}
        svg={<IconeLupa />} type='submit' buttonStyle={BUTTON_TYPE_CLASSES.svg}
        
        ></Button>
        
        <Button texto={'Limpar'} 
        svg ={<IconX/>} 
        color={'white'} 
        bgColor={'#a7a7a7b5'} 
        onClick={limpaCampos} 
        buttonStyle={BUTTON_TYPE_CLASSES.svg}
        width='95px'
        opacity={'0.8'}
        fontSize={'0.9rem'}
        margin={'0.5rem 0 0 1rem'}
        hover={'opacity: 1'}
        />
        </SearchButtonContainer>
        
    
    
    </SearchContainer>

    </>
    )

}

export default SearchForm;
