import { useEffect } from "react";
import { createContext, useState } from "react";
import { onAuthStateChangedListener } from "../utils/firebase/firebase.utils";
import { getAuth } from "firebase/auth";

export const UserContext = createContext({
    currentUserState: null,
    setCurrentUser: () => null
});

export const UserProvider = ({ children }) => {
    const [currentUserState, setCurrentUser] = useState(null);
    
    
    const value = { currentUserState, setCurrentUser }

    useEffect(() => {
        const unsubscribe = onAuthStateChangedListener((user) => {
            if(user) {
                
                
            }
            setCurrentUser(user);
           
        });
 
         return unsubscribe;
     }, []);

    return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}
