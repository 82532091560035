import styled from "styled-components";

export const CheckboxContainer = styled.div`
    width: 100%;
    height: 35px;
    padding-left: 5px;
    margin: ${props => props.margin};
    border-radius: 5px;
    //border: ${props => props.checked ? '1px solid #a5c017' : 'none'};
    background-color: ${props => props.checked ? '#e0ffaeef' : '#ffffff'};
    transition: ${props => props.checked ? 'all 1s ease-out' : 'all 1s ease-out'};
    display: flex;
    align-items: center;
    grid-column: ${props => props.gridColumn};
`;

export const HiddenCheckbox = styled.input.attrs({type: 'checkbox'})`
    overflow: hidden;
    white-space: nowrap;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
`;

export const Text = styled.label`
    color: ${props => props.checked ? '#FFF' : '#555'};
`;
export const StyledCheckbox = styled.label`
    width: 30px;
    height: 30px;
    margin-right: 6px;
    //border-radius: 50%;
    background: rgb(240, 240, 240);
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        display: ${props => props.checked ? 'flex' : 'none'};
    }
    
`;