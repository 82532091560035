import { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { UserContext } from '../../contexts/user.context';

import FormInput from '../../components/form-input/form-input.component';

import FormInputAlt from '../form-input-alt/form-input-alt.component';

import Button, {BUTTON_TYPE_CLASSES} from '../button/button.component';

import { signInAuthUserWithEmailAndPassword } from '../../utils/firebase/firebase.utils';
import { LogInFormStyle } from './sign-in-form.styles';


const defaultFormFields = {
    email: '',
    password: ''
}



const SignInForm = () => {
    const [formFields, setFormFields] = useState(defaultFormFields);
    const { email, password } = formFields;
    const navigate = useNavigate();

    const resetFormFields = () => {
        setFormFields(defaultFormFields)
    }

    const goToMainPage = () => {
       navigate('home')
    }
    /*const signInWithGoogle = async () => {
        await signInWithGooglePopup();
        
    };*/

    const handleSubmit = async (event) => {
        event.preventDefault();
        
        try {
            const {user} = await signInAuthUserWithEmailAndPassword(email, password);
            
            resetFormFields();
            goToMainPage();
        } catch (error) {
            console.log(error)
            
        }
    }

    const handleChange = (event) => {
        const {name, value} = event.target; 

        setFormFields({...formFields, [name]:value})
    }

    return (
        <>
            <h3>Iniciar Sessão - MEI</h3>
            <LogInFormStyle onSubmit={handleSubmit}>
                <FormInputAlt label={'Email'} type='email' name='email' required onChange={handleChange} value={email}/>
                <FormInputAlt label={'Senha'} type='password' name='password' required onChange={handleChange} value={password}/>
                {/*<FormInput type='email' required name='email'onChange={handleChange} placeholder='Email'></FormInput>
                <FormInput type='password' required name='password' onChange={handleChange} placeholder='Senha' ></FormInput>*/}

                <Button texto={'Entrar'} buttonStyle={BUTTON_TYPE_CLASSES.svg} type='submit' margin={'2rem 0 0 auto'} 
                    bgColor={'#396600'} opacity={'0.9'} hover={'opacity: 1'}
                />
                
            </LogInFormStyle>
            
        </>
    )
   
}

export default SignInForm;
