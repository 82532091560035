import styled from "styled-components";


export const HomeContainer = styled.div`
    flex-direction: column;
    display:flex;
    width: 95%;
    margin: 2rem auto;
    box-shadow: 0px 0px 10px 5px rgb(38 41 33 / 25%);
    padding: 1rem;
    border-radius: 5px;
    background-color: white;

`