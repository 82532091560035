import { Display, EditButtonsContainer } from "./display-dados.styles";
import { useNavigate, useParams } from "react-router-dom";
import ExibeDados from "../exibe-dados/exibe-dados.component";
import EditaDados from "../edita-dados/edita-dados.component";
import { useEffect, useState } from "react";
import Button, { BUTTON_TYPE_CLASSES } from "../button/button.component";
import { updateCliente, deleteCliente } from "../../utils/firebase/firebase.utils";
import { ReactComponent as FfPen } from '../../assets/pencil.svg';
import { ReactComponent as TrashIcon } from '../../assets/trash-bin.svg';
import { ReactComponent as SaveIcon} from '../../assets/save-thick.svg';
import { ReactComponent as CancelIcon} from '../../assets/cancel-thick.svg';
import { message } from "antd";



export const cpfMask = value => {
    return value
      .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
      .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1') // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
}

export const phoneMask = (value) => {
    if (!value) return ""
    value = value.replace(/\D/g,'')
    value = value.replace(/(\d{2})(\d)/,"($1) $2")
    value = value.replace(/(\d{5})(\d{4})(\d)/, "$1-$2")
    return value
}

const DisplayDados = ({ props }) => {
    const { nome, cpf, telefone, cnae, email, cep, uf, bairro, logradouro, complementos, numero, nomeFantasia, logInGov, senhaGov, infoExtra, plano } = props;
    const navigate = useNavigate();
    const pegaParam = useParams();
    const idParam = Object.values(pegaParam).toString();

    function DadosCliente(nome, cpf, telefone, cnae, email, cep, uf, bairro, logradouro, complementos, numero,
        nomeFantasia, logInGov, senhaGov, infoExtra, plano) {
        return (
            this.nome = nome,
            this.cpf = cpf,
            this.telefone = telefone,
            this.cnae = cnae,
            this.email = email,
            this.cep = cep,
            this.uf = uf,
            this.bairro = bairro,
            this.logradouro = logradouro,
            this.complementos = complementos,
            this.numero = numero,
            this.nomeFantasia = nomeFantasia,
            this.logInGov = logInGov,
            this.senhaGov = senhaGov,
            this.plano = plano,
            this.infoExtra = infoExtra
            
        )
    };

    const defaultFormFields = new DadosCliente(nome, cpf, telefone, cnae, email, cep, uf, bairro, logradouro, complementos, numero, nomeFantasia, logInGov, senhaGov, infoExtra, plano);
    
    const [formFields, setFormFields] = useState(defaultFormFields);
    const [clickEdit, setClickEdit] = useState(null);
    const [messageApi, contextHolder] = message.useMessage();
    

    const handleFormChange = (event) => {
        event.preventDefault();

        const fieldName = event.target.getAttribute('name');
        const fieldValue = event.target.value;
        const newFormData = { ...formFields };

        if (fieldName == 'cpf') {
            const cpf = cpfMask(fieldValue)
            newFormData[fieldName] = cpf;
        } else if (fieldName == 'telefone') {
            const telefone = phoneMask(fieldValue)
            newFormData[fieldName] = telefone;
        } else {
            newFormData[fieldName] = fieldValue;
        }

        setFormFields(newFormData)
        
    };

    const editButtonClick = (event) => {
        event.preventDefault();
        setClickEdit(true);
        setFormFields(defaultFormFields)
    }

    const cancelButtonClick = (event) => {
        event.preventDefault();

        setFormFields(defaultFormFields);

        setClickEdit(null);
    }

    const saveButtonClick = async (event) => {
        event.preventDefault();

        try {
            await updateCliente(idParam, formFields)
            setFormFields([])
            messageApi.open({
                type: 'success',
                content:'Alterações Salvas',
                duration: '1.5',
                style: {
                    margin: '5rem 0 0 0 ',
                }
            });
            
            setTimeout(() => {
                window.location.reload();
            }, 400);
        } catch (error) {
            messageApi.open({
                type: 'error',
                content:'Não foi possível salvar as alterações',
                duration: '1.5',
                style: {
                    margin: '5rem 0 0 0 ',
                }
            });
            console.log(error, 'não foi possível salvar dados');
            setTimeout(() => {
                window.location.reload();
            }, 400);
        }
    }

    const deleteButtonClick = async (cpf) => {
        //event.preventDefault();

        try {
            await deleteCliente(idParam, cpf);
            navigate('/home');
            window.location.reload();
        } catch (error) {
            console.log(error + 'funcao deletar não teve sucesso')
        }


    }

    

    return (
        <div style={{display:'flex', margin: '0.5rem', justifyContent:'space-between'}}>
            {contextHolder}  
             <Display>
                {
                    Object.keys(defaultFormFields).map((key, index) => (
                        
                        clickEdit == null ? (
                            <ExibeDados key={index} titulo={key} dado={defaultFormFields[key]} />
                            
                        ) : (
                            <EditaDados key={index} handleFormChange={handleFormChange} name={key}  value={formFields[key]}/>
                        )
                    ))
                    
                }
                
            </Display>
            
            {
                clickEdit == null ? (
                    <>
                        <EditButtonsContainer>
                            <Button  buttonStyle={BUTTON_TYPE_CLASSES.svg} onClick={editButtonClick} svg={<FfPen />} width={'30px'} height={'30px'} display={'flex'} 
                            justifyContent={'center'} textAlign={'center'} bgColor={'#2a56d188'} borderRadius={'50%'} margin={'0.5rem 0'}
                            hover={'opacity: 1'}
                            />
                            <Button buttonStyle={BUTTON_TYPE_CLASSES.svg} svg={<TrashIcon />}
                            onClick={() => { if(window.confirm('Realmente deseja excluir o cliente?')){deleteButtonClick(cpf)}}}
                            width={'30px'} height={'30px'} borderRadius={'50%'} bgColor={'orange'} margin={'0.5rem 0'} hover={'opacity: 1'}
                            />
                        </EditButtonsContainer>
                    </>
                ) : (
                    <>
                        <EditButtonsContainer>
                            <Button buttonStyle={BUTTON_TYPE_CLASSES.svg} onClick={saveButtonClick} svg={<SaveIcon />} width={'30px'} height={'30px'}
                            borderRadius={'50%'} margin={'0.5rem 0'} hover={'opacity: 1'}
                            />
                            <Button svg={<CancelIcon />} width={'30px'} height={'30px'} margin={'0.5rem 0'} bgColor={'#d61616'}
                            borderRadius={'50%'} buttonStyle={BUTTON_TYPE_CLASSES.svg} onClick={cancelButtonClick} hover={'opacity: 1'}
                            />
                        </EditButtonsContainer>
                    </>
                )
            }
            
        </div>

    )
}



export default DisplayDados;


