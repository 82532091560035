import SignUpForm from "../../components/sign-up-form/sign-up-form.component";


const SignUpPage = () => {

    return (
        <SignUpForm></SignUpForm>
        
    )
}

export default SignUpPage;