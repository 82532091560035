import styled from "styled-components";

export const NvClienteContainer = styled.div`
    flex-direction: column;
    display:flex;
    width: 95%;
    margin: 2rem auto;
    box-shadow: 0px 0px 10px 5px rgb(38 41 33 / 25%);
    padding: 1rem;
    border-radius: 5px;
    background-color: white;
`

export const CadastroContainer = styled.div`
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    
    button {
        
        font-weight: 600;
        font-size: 0.9rem;
    }
`

export const NovoClienteForm = styled.form`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 40px;
    width: 100%;

    /*&:last-child {
        
        grid-column: span 2 !important;
    }*/
`