import styled from "styled-components";

export const ContainerSignUp = styled.div`
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    text-align: center;
    height:90vh;
    box-shadow: 0px 0px 10px 5px rgb(38 41 33 / 25%);
`

export const ContainerFormSignUp = styled.div`
    margin: 5rem auto;
    width: 60%;
    height: 80%;
    justify-content: flex-start;
    display: flex;
    flex-direction: column;
    
`

export const ContainerLogoSgUp = styled.div`
    width: 80%;
    height: 10%;
    margin: 4rem auto 0 4rem;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;

    svg {
        width: 200px;
        height: 90px;
        align-self: center;
    }
`