import { NavigationContainer, LogoContainer, NavLinks } from "./navigation.styles";

import { ReactComponent as LogoHorizontal } from '../../assets/logo-horizontal.svg';
import { ReactComponent as HomeIcon } from '../../assets/home.svg';
import { ReactComponent as UserIcon} from '../../assets/user3-svgrepo-com.svg';
import { NavLink, useNavigate } from "react-router-dom";
import { useContext } from "react";
import { UserContext } from "../../contexts/user.context";
import { Navigate } from "react-router-dom";

import Button, {BUTTON_TYPE_CLASSES} from "../button/button.component";
import { signOutUser } from "../../utils/firebase/firebase.utils";



const Navigation = () => {
    const { currentUserState } = useContext(UserContext);
    const navigate = useNavigate();

    const verificarStorage = () => {
        let userLog = sessionStorage
        let status;

        if(userLog.length > 0) {
            status = true
        } else {
            status = false
        }
        
        return  status;
    }

    const clickHome = () => {
        navigate('/home');
        window.location.reload();
    }

    return (
        <>
                <>
                    <NavigationContainer>
                        <LogoContainer>
                            <LogoHorizontal />
                        </LogoContainer>
                        <NavLinks>
                        {verificarStorage() !== true ? (
                            <Navigate to="/" replace></Navigate>
                        ) : (<>
                            {/*<NavLink to='/home' ><HomeIcon /></NavLink>*/}
                            <Button svg={<HomeIcon/>} onClick={clickHome} bgColor={'#ffffff'} buttonStyle={BUTTON_TYPE_CLASSES.svg} 
                            display={'flex'} flexDirection={'column'} texto={'Início'} color={'black'} fontSize={'0.6rem'} width={'80px'} 
                            height={'60px'} margin={'auto'} hover={'opacity: 1'}
                            />
                            <Button type="button" svg={<UserIcon />} text={'Log Off'} onClick={signOutUser} buttonStyle={BUTTON_TYPE_CLASSES.svg}
                            texto={'Sair'} display={'flex'} flexDirection={'column'} color={'black'} fontSize={'0.6rem'} height={'60px'}
                            width={'80px'} margin={'auto'} bgColor={'#ffffff'} hover={'opacity: 1'}
                            />
                            </>
                        )}
                        </NavLinks>
                        
                    </NavigationContainer>
                </>
            
        </>
    )
}

export default Navigation;