import styled from "styled-components";

export const SearchContainer = styled.form`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 0.4rem;
    grid-column-gap: 7rem;
    margin: 0 1rem 1rem 1rem;

`
