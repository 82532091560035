
import { useContext, useEffect, useState } from "react";
import { ClientesContext } from "../../contexts/clientes.context";
import { ClienteContext } from "../../contexts/cliente.context";
import { Link, useNavigate } from "react-router-dom";
import { PaginationContainer, Tabela } from "./table.styles";
import Button, { BUTTON_TYPE_CLASSES } from "../button/button.component";
import DataRowClient from "../data-row-client/data-row-client.component";
import PaginationSelf from "../pagination-self/pagination-self.component";
import { Pagination, ConfigProvider } from "antd";


const tableHeaders = [
    'Nome',
    'CPF',
    'Telefone',
    'Registrado Em',
    'Tipo',
    ''
]


const Table = () => {


    const { clientesMap, clientesFiltered } = useContext(ClientesContext);

    const [info, setInfo] = useState(clientesMap);
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(5);
    const navigate = useNavigate();

    useEffect(() => {
        setInfo(clientesMap)
    }, [clientesMap])

    const clickHandler = async (id) => {

        navigate(`/menucliente/${id}`)
    }

    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = info.slice(indexOfFirstPost, indexOfLastPost);

    const paginate = pageNumber => setCurrentPage(pageNumber);

    return (
        <>
        <>
       <Tabela key={'tabela'}>
            <thead>
                <tr>
                    {
                        tableHeaders.map((item) => (<th key={item}>{item}</th>))
                    }
                </tr>
            </thead>
            <tbody>
                <DataRowClient info={currentPosts} clickHandler={clickHandler}/>
                
            </tbody>
        </Tabela>
        </>
        <PaginationContainer>
        <ConfigProvider theme={{token:{colorPrimary: '#a5c017'}}}>
            <Pagination
            
            pageSize={postsPerPage}
            total={info.length}
            current={currentPage}
            onChange={value => setCurrentPage(value)}
            />
            
        </ConfigProvider>
        </PaginationContainer>
        </>
    )
}

export default Table;
