import styled from "styled-components";

import FormInput from "./form-input.component";

export const Input = styled.input`
    background-color: #F4F5F8;
    border-radius: 40px;
    padding: 0.5rem;
    margin-top:0.3rem;
    border:none;
    width: 400px;
    

    &:focus {
        outline:none;
        background-color: #dbdbdb;
    }
`

export const ContainerInput = styled.div`
    display: flex;
    flex-direction: column;
    margin-top:0.5rem;
`

export const HiddenInput = styled.input`
    display:none;
`
