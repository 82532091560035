import styled from "styled-components";

import { ReactComponent as LogoSvg} from '../../assets/brand-logo-full.svg';

export const LogoContaJa = styled(LogoSvg)`
    width: 225px;
    height:225px;
`
export const LogInContainer = styled.div`
    display:flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    align-items:center;
    height: 92vh;
    background-color:white;
    box-shadow: 0px 0px 10px 5px rgb(38 41 33 / 25%);
`

export const ImageContainer = styled.div`
    display: flex;
    justify-content: center;

`

export const Subtitulo = styled.span`
    font-size: 0.9rem;
`
export const CadastroContainer = styled.div`
    margin-top: 1.7rem;

`