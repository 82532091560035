import { Navigate, useNavigate, Link } from 'react-router-dom';

import { CadastroContainer, ImageContainer, LogInContainer, LogoContaJa, Subtitulo } from './authentication.styles';
import SignInForm from '../../components/sign-in-form/sign-in-form.component';

const Authentication = () => {
    

    return (
        <LogInContainer className='login-container'>

            <ImageContainer className='image-container'>
                <LogoContaJa />
            </ImageContainer>

            <SignInForm></SignInForm>

            <CadastroContainer>
                <Link to='/signup' ><Subtitulo>Ainda não tem conta? Clique aqui</Subtitulo></Link>
            </CadastroContainer>
        </LogInContainer>

    )
}

export default Authentication;