import { Group, SearchInput } from "./search-box.styles";


const SearchBox = ({label, ...otherProps}) => {
    return (
        <Group >
            <label>{label}</label>
            <SearchInput className='search-input' {...otherProps}></SearchInput>
        </Group>
        
    )
}

export default SearchBox;
