import { CheckboxContainer, HiddenCheckbox, Text, StyledCheckbox } from "./checkbox.styles";
import { useState } from "react";

import CheckIcon from '../../assets/tick-phat.svg'
import { useEffect } from "react";

const CheckBox = ({ label, texto, value, name, onClick, load, dadosForm, ...props }) => {
    
    const [checked, setChecked] = useState(false);
    
    useEffect(()=> {
        if(value == true) {
            setChecked(true)
        } else {
            setChecked(false)
        }
        
    }, [load]);

    

    const handleCheckboxChange = () => {
        dadosForm[name] = !checked;
        setChecked(!checked)
        
    }

    return (
        <CheckboxContainer checked={checked} onClick={handleCheckboxChange} {...props}>
            <HiddenCheckbox  onChange={handleCheckboxChange} checked={checked} value={value} name={name} {...props}/>
            <StyledCheckbox checked={checked}>
                <img 
                    src={CheckIcon}
                />
            </StyledCheckbox>
            <Text cheked={checked}> {label} </Text>
        </CheckboxContainer>

    )

}

export default CheckBox;