import { Routes, Route } from 'react-router-dom';

import Authentication from './routes/authentication/authentication.component';
import Home from './routes/home/home.component';
import NovoCliente from './routes/novo-cliente/novo-cliente.component';
import SignUpPage from './routes/sign-up/sign-up-page.component';
import MenuCliente from './routes/menu-cliente/menu-cliente.component';



const App = () => {
  
  return (
    <Routes >
      <Route path ='/' element={<Authentication />}/>
      <Route path='home' element={<Home />} />
      <Route path='signup' element={<SignUpPage />}/>
      <Route path='nvcliente' element={<NovoCliente />}/>
      <Route path='menucliente/*' element={<MenuCliente />} />
    </Routes>
  )
}

export default App;
