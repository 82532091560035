import { createContext, useEffect, useState } from "react";

import { getClientsDocuments } from "../utils/firebase/firebase.utils";

export const ClientesContext = createContext({
    clientesMap: [],
    clientesFiltered: [],
    retornaPesquisa: () => { },
    didSearch: false
})

const filtraClientes = (entradas, clientesTotal) => {
    let clientes = clientesTotal;
    let clientesPesquisados;
    
    console.log(entradas.hasOwnProperty('dataIni') && entradas.hasOwnProperty('dataFim'))
    if (entradas.hasOwnProperty('dataIni') && entradas.hasOwnProperty('dataFim')) {
        let diaInicio = (Object.values(entradas)[0]).replaceAll('-', '/')
        let diaFim = (Object.values(entradas)[1]).replaceAll('-', '/')
        let data = new Date(diaInicio).getTime()
        let dataFim = new Date(diaFim).getTime()
        clientesPesquisados = clientes.filter((cliente, idx) => {
            if ((cliente['createdAt']['seconds'] * 1000 >= data) && ((cliente['createdAt']['seconds'] * 1000) <= (dataFim + 86399999))) {
                
                return cliente;
            }
        })
    } else {
        for (let i = 0; i < Object.keys(entradas).length; i++) {
            
            if (Object.keys(entradas)[i] == 'dataIni' || Object.keys(entradas)[i] == 'dataFim') {
                let dia = (Object.values(entradas)[i]).replaceAll('-', '/')
                let data = new Date(dia).getTime()
                if (Object.keys(entradas)[i] == 'dataIni') {
                    clientesPesquisados = clientes.filter((cliente, idx) => {
                        if (cliente['createdAt']['seconds'] * 1000 >= data) {
                        
                            return cliente;
                        }
                    })
                } else if (Object.keys(entradas)[i] == 'dataFim') {
                    data += 86399999;
                    clientesPesquisados = clientes.filter((cliente, idx) => {
                        if ((cliente['createdAt']['seconds'] * 1000) <= data) {
                            return cliente;
                        }
                    })
                } 
    
            } else {
                clientesPesquisados = clientes.filter((cliente, idx) => {
                    if ((clientes[idx][(Object.keys(entradas)[i])]).toLowerCase().includes((Object.values(entradas)[i]).toLowerCase())) {
                        return cliente;
                    }
                })
            }
    }
    
    }

    return clientesPesquisados;
}


export const ClientesProvider = ({ children }) => {
    const [clientesMap, setClientesMap] = useState([]);

    const getClientsMap = async () => {
        try {
            const clientMap = await getClientsDocuments();
            setClientesMap(clientMap);

        } catch (error) {
            console.log(error + 'nao foi possivel pegar clientes')
        }
    }

    useEffect(() => {
        getClientsMap();
    }, [])

    const retornaPesquisa = (entradas, clientesTotal) => {
        setClientesMap(filtraClientes(entradas, clientesTotal))
    }

    return (
        <ClientesContext.Provider value={{ clientesMap, getClientsMap, retornaPesquisa }}>
            {children}
        </ClientesContext.Provider>
    );
}


