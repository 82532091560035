import { createContext, useState } from 'react';
import { useEffect } from 'react';
import { getClienteData } from '../utils/firebase/firebase.utils';




export const ClienteContext = createContext({
    clienteInfo: []
});

export const ClienteProvider = ({children}) => {
    const [clienteInfo, setClienteInfo] = useState([]);

    const pegaClienteInfo = async (id) => {
        try {
            const clienteInfo = await getClienteData(id);
            setClienteInfo(clienteInfo)
            
        } catch (error) {
            console.log(error + 'não foi possível retornar informação do cliente selecionado')
        }
    }

    const value = { clienteInfo, pegaClienteInfo }

    return (
    <ClienteContext.Provider value={value}>{children}</ClienteContext.Provider>
    )

}

