import { ContainerInput, InputDados, LabelEdit } from "./edita-dados.styles";


const EditaDados = ({ handleFormChange, ...props}) => {
    

    const TITULOS = {
        nome: 'Nome: ',
        cpf: 'CPF: ',
        telefone: 'Telefone: ',
        cnae: 'CNAE: ',
        email: 'Email: ',
        cep: 'CEP: ',
        uf: 'UF: ',
        bairro: 'Bairro: ',
        logradouro: 'Logradouro: ',
        complementos: 'Complemento: ',
        numero: 'Número: ',
        logInGov: 'LogIn Gov: ',
        senhaGov: 'Senha Gov: ',
        infoExtra: 'Informações Extra: ',
        nomeFantasia: 'Nome Fantasia: ',
        plano: 'Plano: '
    }


    return (
        <ContainerInput>
            <LabelEdit>{TITULOS[props.name]}</LabelEdit>
            <InputDados onChange={handleFormChange} {...props} />
        </ContainerInput>
    )
    
}

export default EditaDados;

