import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { createClientFromForm } from "../../utils/firebase/firebase.utils";
import FormInput from "../../components/form-input/form-input.component";
import Button, { BUTTON_TYPE_CLASSES } from "../../components/button/button.component";
import { CadastroContainer, NovoClienteForm, NvClienteContainer } from "./novo-cliente.styles";
import { HiddenInput } from "../../components/form-input/form-input.styles";
import Navigation from "../../components/navigation/navigation.component";
import { useContext } from "react";
import { UserContext } from "../../contexts/user.context";
import FormInputAlt from "../../components/form-input-alt/form-input-alt.component";
import { ReactComponent as PinIcon } from '../../assets/location-indicator-red-svgrepo-com.svg';
import CheckBox from "../../components/checkbox-input/checkbox.component";
import { ReactComponent as FileIcon } from '../../assets/file-chuncky.svg';
import { message } from "antd";


const defaultFormFields = {
    nome: '',
    cpf: '',
    telefone: '',
    email: '',
    logInGov: '',
    senhaGov: '',
    cnae: '',
    cep: '',
    uf: '',
    bairro: '',
    logradouro: '',
    complementos: '',
    numero: '',
    nomeFantasia: '',
    infoExtra: '',
    cnpj: '',
    plano: '',
    confirmPag: false,
    pedirDocs: false,
    colocarPlanilha: false,
    conferirDocs: false,
    formalize: false,
    ccmei: false,
    enviarComprovante: false,
    criarAcesso: false,
    enviarAcesso: false,
    completarPlanilha: false,
    fluxo: false,
    testarGov: false,
    confirmInfo: false,
    cartaoCnpj: false,
    clienteAntigo: false

}

export const cpfMask = value => {
    return value
      .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
      .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1') // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
}

export const phoneMask = (value) => {
    if (!value) return ""
    value = value.replace(/\D/g,'')
    value = value.replace(/(\d{2})(\d)/,"($1) $2")
    value = value.replace(/(\d{5})(\d{4})(\d)/, "$1-$2")
    return value
}

const NovoCliente = () => {
    

    const [formFields, setFormFields] = useState(defaultFormFields);
    const { currentUser } = useContext(UserContext);
    const [load, setLoad] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();

    const { nome, cpf, telefone, email, logInGov, senhaGov, cnae, cep, uf, bairro, logradouro, complementos, numero, nomeFantasia, infoExtra, cnpj, 
    clienteAntigo, plano} = formFields;

    const { register, setValue } = useForm();
    useEffect(() => {
        setFormFields(defaultFormFields);
        setLoad(true)
    }, [])
    const resetFormFields = () => {
        setFormFields(defaultFormFields);
    }

    const submitHandler = async (event) => {
        event.preventDefault();

        try {
            const client = formFields;
            const response = await createClientFromForm(client);

            if (response) {
                messageApi.open({
                    type: 'success',
                    content:'Cliente Cadastrado com Sucesso',
                    duration: '2.5',
                    style: {
                        margin: '5rem 0 0 0 ',
                        
                    }
                });
                
                //alert('Cliente cadastrado');
                resetFormFields();
            } else {
                messageApi.open({
                    type: 'error',
                    content:'CPF já cadastrado',
                    duration: '2.5',
                    style: {
                        margin: '5rem 0 0 0 ',
                        
                    }
                    
                });
                //alert('CPF já registrado');
            }

        } catch (error) {
            console.log(error, 'não foi possível cadastrar')
        }

    }

    const handleChange = (event) => {
        const { name, value } = event.target;

        setFormFields({ ...formFields, [name]: value })
    }
    // para evitar checagens a cada input optei por criar funcoes diferentes

    const handleCpfChange = (event) => {
        const { name, value } = event.target;
        const cpf = cpfMask(value);

        setFormFields({ ...formFields, [name]: cpf })
    }

    const handleTelChange = (event) => {
        const { name, value } = event.target;
        const tel = phoneMask(value)

        setFormFields({ ...formFields, [name]: tel })
    }



    const pesquisarCep = async (cep) => {
        let url = `https://viacep.com.br/ws/${cep}/json/`;
        const headers = {
            headers: {
                "Access-Control-Allow-Headers": "*",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "*"
            }
        }
        try {
            const data = await fetch(url);
            const dados = await data.json();
            setFormFields({
                nome: nome,
                cpf: cpf,
                telefone: telefone,
                email: email,
                logInGov: logInGov,
                senhaGov: senhaGov,
                cnae: cnae,
                cep: dados.cep,
                uf: dados.uf,
                bairro: dados.bairro,
                logradouro: dados.logradouro,
                complementos: complementos,
                numero: numero,
                nomeFantasia: nomeFantasia,
                infoExtra: infoExtra,
                cnpj: cnpj,
                plano: plano,
                confirmPag: false,
                pedirDocs: false,
                colocarPlanilha: false,
                conferirDocs: false,
                formalize: false,
                ccmei: false,
                ccmei: false,
                enviarComprovante: false,
                criarAcesso: false,
                enviarAcesso: false,
                completarPlanilha: false,
                fluxo: false,
                testarGov: false,
                confirmInfo: false,
                cartaoCnpj: false,
                clienteAntigo: false
                })
        } catch (error) {
            alert('CEP Incorreto');
            console.log(error + 'não foi possível consumir api correios');
        }
    }

    return (
        <>
            {contextHolder}
            <NvClienteContainer>
            <Navigation />
            <CadastroContainer>
                <h3>Cadastro de clientes</h3>

                <NovoClienteForm onSubmit={submitHandler}>
                    <FormInputAlt type='text' onChange={handleChange} value={nome} label='Nome' name='nome' ></FormInputAlt>
                    <FormInputAlt type='text' onChange={handleCpfChange} value={cpf} label='CPF' name='cpf' ></FormInputAlt>
                    <FormInputAlt type='text' onChange={handleTelChange} value={telefone} label='Telefone' name='telefone' maxLength='15' ></FormInputAlt>
                    <FormInputAlt type='text' onChange={handleChange} value={email} label='Email' name='email' ></FormInputAlt>
                    <FormInputAlt type='text' onChange={handleChange} value={logInGov} label='Login Gov' name='logInGov' ></FormInputAlt>
                    <FormInputAlt type='text' onChange={handleChange} value={senhaGov} label='Senha Gov' name='senhaGov' ></FormInputAlt>
                    <FormInputAlt type='text' onChange={handleChange} value={cnae} label='CNAEs' name='cnae' ></FormInputAlt>
                    <FormInputAlt type='text' onChange={handleChange} value={nomeFantasia} label='Nome Fantasia' name='nomeFantasia' placeholder=''></FormInputAlt>
                    <FormInputAlt type='text' onChange={handleChange} value={cep} label='CEP' name='cep' ></FormInputAlt>
                    
                    <Button texto={'Consulta CEP'} onClick={() => { pesquisarCep(cep) }} type='button' buttonStyle={BUTTON_TYPE_CLASSES.svg}
                    svg={<PinIcon />} width={'180px'} margin={'auto'} color={'black'} fontWeight={'500'} bgColor={'#f0f0f0'}
                    />
                    
                    <FormInputAlt type='text' onChange={handleChange} value={uf} label='UF' name='uf' ></FormInputAlt>
                    <FormInputAlt type='text' onChange={handleChange} value={bairro} label='Bairro' name='bairro' ></FormInputAlt>
                    <FormInputAlt type='text' onChange={handleChange} value={logradouro} label='Logradouro' name='logradouro' ></FormInputAlt>
                    <FormInputAlt type='text' onChange={handleChange} value={numero} label='Numero' name='numero' ></FormInputAlt>
                    <FormInputAlt type='text' onChange={handleChange} value={complementos} label='Complemento' name='complementos' ></FormInputAlt>
                    <FormInputAlt type='text' onChange={handleChange} value={plano} label='Plano Contratado' name='plano'></FormInputAlt>
                    <FormInputAlt type='text' onChange={handleChange} value={infoExtra} label='Informações Adicionais' name='infoExtra'></FormInputAlt>
                    <CheckBox /*gridColumn={'span 2'}*/ name='clienteAntigo' value={clienteAntigo} label={'Cliente anterior?'} load={load} dadosForm={formFields}
                        margin={'auto'}
                    />

                    <HiddenInput type='checkbox' value='false' name='confirmPag'></HiddenInput>
                    <HiddenInput type='checkbox' value='false' name='pedirDocs'></HiddenInput>
                    <HiddenInput type='checkbox' value='false' name='colocarPlanilha'></HiddenInput>
                    <HiddenInput type='checkbox' value='false' name='conferirDocs'></HiddenInput>
                    <HiddenInput type='checkbox' value='false' name='formalize'></HiddenInput>
                    <HiddenInput type='checkbox' value='false' name='ccmei'></HiddenInput>
                    <HiddenInput type='checkbox' value='false' name='enviarComprovante'></HiddenInput>
                    <HiddenInput type='checkbox' value='false' name='criarAcesso'></HiddenInput>
                    <HiddenInput type='checkbox' value='false' name='enviarAceso'></HiddenInput>
                    <HiddenInput type='checkbox' value='false' name='completarPlanilha'></HiddenInput>
                    <HiddenInput type='checkbox' value='false' name='fluxo'></HiddenInput>
                    <HiddenInput type='checkbox' value='false' name='testarGov'></HiddenInput>
                    <HiddenInput type='checkbox' value='false' name='confirmInfo'></HiddenInput>
                    <HiddenInput type='checkbox' value='false' name='cartaoCnpj'></HiddenInput>
                    
                </NovoClienteForm>
                <Button texto={'Cadastrar'} width={'200px'} onClick={submitHandler} svg={<FileIcon />} buttonStyle={BUTTON_TYPE_CLASSES.svg} 
                type='submit' bgColor={'#396600'} 
                />
            </CadastroContainer>
            </NvClienteContainer>
            
        </>




    )
}

export default NovoCliente;