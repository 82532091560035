import styled from "styled-components";

export const Display = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin: 0.6rem 0 1.5rem;
    column-gap: 20px;
    row-gap: 10px;
    flex: 1;

    div:last-of-type {
        grid-column: span 3;
    }

`

export const EditButtonsContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 0.8rem;
`

