import { useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import { getClienteData, updateCliente } from "../../utils/firebase/firebase.utils";
import Button, { BUTTON_TYPE_CLASSES } from "../../components/button/button.component";
import Navigation from "../../components/navigation/navigation.component";
import DisplayDados from "../../components/display-dados/display-dados.component";
import ProgressBar from "../../components/progress-bar/progress-bar.component";
import { useContext } from "react";
import { UserContext } from "../../contexts/user.context";
import { FormMenuCliente, MenuClienteContainer } from "./menu-cliente.styles";
import CheckBox from "../../components/checkbox-input/checkbox.component";
import { ReactComponent as SaveIconSml} from '../../assets/save-thick-smaller.svg';
import { message } from "antd";




const MenuCliente = () => {
    const pegaParam = useParams();
    const idParam = Object.values(pegaParam).toString();
    const [dados, setDados] = useState([]);
    const { currentUser } = useContext(UserContext);
    const [carregando, setCarregando] = useState(true);
    const [messageApi, contextHolder] = message.useMessage();

    useEffect(() => {
        const pegaInfo = async (id) => {
            try {
                const clienteInfo = await getClienteData(id);
                setDados(clienteInfo);
                setCarregando(false);
            } catch (error) {
                console.log(error + 'nao foi possivel pegar informação do cliente')
            }
        }
        pegaInfo(idParam);
    }, []);


    function FormDados(colocarPlanilha, conferirDocs, pedirDocs, formalize, confirmPag,
        ccmei, criarAcesso, completarPlanilha, fluxo, testarGov, confirmInfo, cartaoCnpj, enviarAcesso) {
        this.colocarPlanilha = colocarPlanilha;
        this.conferirDocs = conferirDocs;
        this.pedirDocs = pedirDocs;
        this.formalize = formalize;
        this.confirmPag = confirmPag;
        this.ccmei = ccmei;
        this.criarAcesso = criarAcesso;
        this.completarPlanilha = completarPlanilha;
        this.fluxo = fluxo;
        this.testarGov = testarGov;
        this.confirmInfo = confirmInfo;
        this.cartaoCnpj = cartaoCnpj;
        this.enviarAcesso = enviarAcesso;
    }

    const { colocarPlanilha, conferirDocs, pedirDocs, formalize,
        confirmPag, ccmei, criarAcesso, completarPlanilha, fluxo,
        testarGov, confirmInfo, cartaoCnpj, enviarAcesso
    } = dados;

    const dadosForm = new FormDados(colocarPlanilha, conferirDocs, pedirDocs, formalize,
        confirmPag, ccmei, criarAcesso, completarPlanilha, fluxo, testarGov, confirmInfo, cartaoCnpj, enviarAcesso)



    const submitHandler = async (id, event) => {
        event.preventDefault();

        try {
            const dadosChecklist = dadosForm;
            await updateCliente(id, dadosChecklist)
            //navigate(`/menucliente/${id}`)
            messageApi.open({
                type: 'success',
                content:'Alterações Salvas',
                duration: '1.5',
                style: {
                    margin: '5rem 0 0 0 ',
                }
            });
            
            setTimeout(() => {
                window.location.reload();
            }, 700)
            
        } catch (error) {
            messageApi.open({
                type: 'error',
                content:'Não foi possível salvar as alterações, tente novamente',
                duration: '1.5',
                style: {
                    margin: '5rem 0 0 0 ',
                }
            });
            console.log(error, 'não foi possível salvar dados');
            setTimeout(() => {
                window.location.reload();
            }, 700)
        }
    }

    const handleChange = (event) => {
        const { name, checked } = event.target;

        dadosForm[name] = checked;
        console.log(dadosForm)
              
    }

    const contaTrues = (dados) => {
        let trues = 0;
        let porcent = 0;
        Object.values(dados).forEach(dado => {
            if (dado === true) {
                trues++;
            }
        })
        porcent = Math.round((100/13) * trues)
        return porcent;
    }
    
    return (
        
        <>
            {contextHolder}
            {
            currentUser === null ? (
                <Navigate to="/" replace />
            ) : (
            <>
            <MenuClienteContainer>
            <Navigation />

            <DisplayDados props={dados} />
    
            <ProgressBar bgcolor={'#396600'} dados={dadosForm} completed={contaTrues(dadosForm)}/>
            
            <FormMenuCliente onSubmit={event => { submitHandler(idParam, event) }}>
                
                <CheckBox load={carregando} dadosForm={dadosForm} margin={'0.5rem 4px'} value={dadosForm.confirmPag} name='confirmPag' label={'Confirmar o pagamento no sistema'} />
                <CheckBox load={carregando} dadosForm={dadosForm} margin={'0.5rem 4px'} value={dadosForm.colocarPlanilha} name='colocarPlanilha'  label={'Colocar a venda na planilha do MEI'}  />
                <CheckBox load={carregando} dadosForm={dadosForm} margin={'0.5rem 4px'} value={dadosForm.pedirDocs} name='pedirDocs' label={'Solicitar ao cliente os dados necessários:'}   />
                <CheckBox load={carregando} dadosForm={dadosForm} margin={'0.5rem 4px'} value={dadosForm.conferirDocs} name='conferirDocs'  label={'Confirmar se o cliente mandou todos os dados necessários'}  />
                <CheckBox load={carregando} dadosForm={dadosForm} margin={'0.5rem 4px'} value={dadosForm.testarGov} name='testarGov' label={'Testar a conta Gov encaminhada'}   />
                <CheckBox load={carregando} dadosForm={dadosForm} margin={'0.5rem 4px'} value={dadosForm.formalize} name='formalize' label={'Formalizar solicitação'}   />
                <CheckBox load={carregando} dadosForm={dadosForm} margin={'0.5rem 4px'} value={dadosForm.confirmInfo} name='confirmInfo' label={'Conferir informações e finalizar'}   />
                <CheckBox load={carregando} dadosForm={dadosForm} margin={'0.5rem 4px'} value={dadosForm.ccmei} name='ccmei' label={'Emitir a CCMEI e enviar para o cliente'}   />
                <CheckBox load={carregando} dadosForm={dadosForm} margin={'0.5rem 4px'} value={dadosForm.cartaoCnpj} name='cartaoCnpj' label={'Emitir o cartão CNPJ e enviar para o cliente'}   />
                <CheckBox load={carregando} dadosForm={dadosForm} margin={'0.5rem 4px'} value={dadosForm.criarAcesso} name='criarAcesso' label={'Criar acesso do cliente na plataforma'}   />
                <CheckBox load={carregando} dadosForm={dadosForm} margin={'0.5rem 4px'} value={dadosForm.enviarAcesso} name='enviarAcesso' label={'Enviar login e senha da plataforma para o cliente'}   />
                <CheckBox load={carregando} dadosForm={dadosForm} margin={'0.5rem 4px'} value={dadosForm.completarPlanilha} name='completarPlanilha' label={'Completar planilha do MEI'}   />
                <CheckBox load={carregando} dadosForm={dadosForm} margin={'0.5rem 4px'} value={dadosForm.fluxo} name='fluxo' label={'Preencher planilha de fluxo'}   />
                

                <Button texto={'Salvar'} margin={'2rem auto 1rem'} width={'180px'} height={'40px'} buttonStyle={BUTTON_TYPE_CLASSES.svg} type='submit' 
                svg={<SaveIconSml />} bgColor={'#396600'} fontSize={'1rem'} fontWeight={'500'} hover={'opacity: 1'}
                >

                </Button>
            </FormMenuCliente>
            </MenuClienteContainer>
            </>
            )
        }
        </>
    )
}

export default MenuCliente;

