import { ContainerDados, TituloDados, ConteudoDados } from "./exibe-dados.styles";
import Modal from "antd/es/modal/Modal";
import { useState } from "react";
import { Button } from "antd";

const ExibeDados = ({ titulo, dado }) => {

    const TITULOS = {
        nome: 'Nome: ',
        cpf: 'CPF: ',
        telefone: 'Telefone: ',
        cnae: 'CNAE: ',
        email: 'Email: ',
        cep: 'CEP: ',
        uf: 'UF: ',
        bairro: 'Bairro: ',
        logradouro: 'Rua: ',
        complementos: 'Complemento: ',
        numero: 'Número: ',
        logInGov: 'LogIn Gov: ',
        senhaGov: 'Senha Gov: ',
        infoExtra: 'Informações Extra: ',
        nomeFantasia: 'Nome Fantasia: ',
        plano: 'Plano: '
    }

    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        
        setIsModalOpen(false);
    };

    return (
        <>
        <ContainerDados onClick={showModal}>
            <TituloDados>{TITULOS[titulo]} </TituloDados>
            <ConteudoDados>{dado}</ConteudoDados>
            
        </ContainerDados>
        <Modal title={TITULOS[titulo]} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}
            footer={null}
            >{dado}</Modal>
        </>
    )
}

export default ExibeDados;

