import { useNavigate, redirect, Navigate } from "react-router-dom";

import Button, { BUTTON_TYPE_CLASSES } from "../../components/button/button.component";
import Table from "../../components/table/table.component";
import { useContext } from "react";
import { UserContext } from "../../contexts/user.context";
import Navigation from "../../components/navigation/navigation.component";
import SearchForm from "../../components/search-form/search-form.component";
import { HomeContainer } from "./home.styles";
import { ReactComponent as AddIcon } from '../../assets/add-solid-denali.svg'



const Home = () => {
    const { currentUserState } = useContext(UserContext);
    const navigate = useNavigate();

    
    const goToNovoCliente = () => {
        navigate('/nvcliente')
    }

    
    return (
        <>
            <HomeContainer>
            <Navigation />
                <h3 style={{marginLeft: '1rem'}}> Gerenciador MEIs</h3>
                <SearchForm />
                <Button texto={'Novo Cliente'} bgColor={'#396600'} 
                hover={'opacity: 1;'} svg={<AddIcon />} buttonStyle={BUTTON_TYPE_CLASSES.svg} 
                onClick={goToNovoCliente} margin={'5rem 1rem 0 0'} alignSelf={'flex-end'}/>
                
                <Table />
            </HomeContainer>
                
        </>
    )
}

export default Home;
