import styled from "styled-components";

export const Tabela = styled.table`
    border: 0.5px solid #f4f5f8;
    border-collapse: collapse;
    margin: 0.5rem auto;
    width:98%;
    

    th, td {
        border: 1px solid #dbdbdb88;
        padding: 0.4rem;
    }
    
    th {
        font-size: 0.9rem;
    }
`

export const PaginationContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin: 2rem 1rem 0 0;
`