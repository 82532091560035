import styled from "styled-components";

const corTexto = '#FFFFFF';
//const corFundo = '#B1CE0B';
const corFundo ='#a5c017'
const corGoogle = '#34ac54';
const corCinzaBotao = '#C4C4D7'
const preto = '#000000'

export const BotaoBasico = styled.button`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: ${corTexto};
    background-color: ${corFundo};
    cursor: pointer;
    outline:none;
    border: 1px solid transparent;
    text-align:center;
    width: 130px;
    height: 40px;
    border-radius: 10px;
    margin-top: 2rem;
    font-size: 0.9rem;
    font-weight: 600;
    opacity:0.9;
    

`

export const BotaoMini = styled(BotaoBasico)`
    color: ${props => props.color};
    width: 50px;
    height: 30px;
    background-color: ${props => props.bgColor};
    margin: auto 0;
`
export const BotaoSignIn = styled(BotaoBasico)`
    margin: 2rem 0 0 auto;
    background-color: #396600;

`

export const BotaoSvg = styled(BotaoBasico)`
    justify-content: space-evenly;
    color: ${props => props.color};
    background-color: ${props => props.bgColor};
    width: ${props => props.width};
    height: ${props => props.height};
    opacity: ${props => props.opacity};
    font-size: ${props => props.fontSize};
    margin: ${props => props.margin};
    align-self: ${props => props.alignSelf};
    display: ${props => props.display};
    flex-direction: ${props => props.flexDirection};
    align-items: ${props => props.alignItems};
    text-align: ${props => props.textAlign};
    border-radius: ${props => props.borderRadius};
    padding: ${props => props.padding};
    font-weight: ${props => props.fontWeight};

    &:hover {
        ${props => props.hover}
    }
    
`


