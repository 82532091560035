import Button, {BUTTON_TYPE_CLASSES} from "../button/button.component";
import { ReactComponent as RightArrow} from '../../assets/right-svgrepo-com-chikin.svg'
import { STr, ClienteTipo, TdTipoCliente } from "./data-row-client.styles";

const DataRowClient = ({info, clickHandler}) => {
    
    
    return (
    <>
    {info &&
        info.map((item, idx) => (
            <STr key={idx}>
                <td key={item.nome} >{item.nome}</td>
                <td key={item.cpf} >{item.cpf}</td>
                <td key={item.telefone} >{item.telefone}</td>
                <td key={item.dateString} >{item.dateString}</td>
                <td key={item.id} >
                    {
                        item.clienteAntigo === true ? (
                            <ClienteTipo bgColor='#a7a7a75c' >Anterior</ClienteTipo>
                        ) : (
                            <ClienteTipo bgColor='#a4c01799' >Novo</ClienteTipo>
                        )
                    }
                </td>
                <td>
                    <Button svg={<RightArrow />} buttonStyle={BUTTON_TYPE_CLASSES.svg} 
                    onClick={() => {clickHandler(item.id)}} bgColor={'transparent'} margin={'auto'} 
                    width={'50px'} height={'30px'}
                    >
                    </Button>
                </td> 
            </STr>
        )
    )}
    </>
    )
}

export default DataRowClient;